<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Agosto 2024                                                     ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <blueHeader :itemsHeader="itemsHeader" />
  
    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>

  </section>
  </template>
   
<script>
import { Role } from "@/router/role.js";
import BlueHeader from '../../../../components/BlueHeader.vue';
  
export default {
  components: { BlueHeader },
  name: 'Dispensation',
  data: () => ({
    itemsHeader: [
      {
        name: 'Solicitudes portal de medicamentos',
        link: { name: 'modules.reports.dispensation.solicitudMedicamentos' },
        rol: Role.Reportes_Dispensacion_SolicitudMedicamentos,
      },
    ]
  }),
}
</script>
   
<style scoped>
  .subHeader-content {
    width: 100%;
  }
</style>